<template>
  <div>
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="企业id" v-show="false">
            <a-input v-decorator="['corporation_id', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="车牌号">
            <a-input v-decorator="['number', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="进口货物信息">
            <a-input v-decorator="['entrance', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="出口货物信息">
            <a-input v-decorator="['exit', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="进口图片信息" v-show="false">
            <a-input v-decorator="['entrance_img', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="进口图片上传">
            <div class="clearfix">
              <a-upload
                v-decorator="[
                  'entrance_img_all',
                  {
                    valuePropName: 'entrance_img_all'
                  },
                ]"
                name="file"
                :action="actionUrl"
                :data="new_multipart_params"
                list-type="picture-card"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-form-item>
          <a-form-item label="出口图片信息" v-show="false">
            <a-input v-decorator="['exit_img', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="出口图片上传">
            <div class="clearfix">
              <a-upload
                v-decorator="[
                  'exit_img_all',
                  {
                    valuePropName: 'exit_img_all'
                  },
                ]"
                name="file"
                accept="image/png, image/jpeg, image/jpg"
                :action="actionUrl"
                :data="new_exit_multipart_params"
                list-type="picture-card"
                :file-list="exitfileList"
                :before-upload="exitbeforeUpload"
                @preview="exithandlePreview"
                @change="exithandleChange"
              >
                <div v-if="exitfileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
              <a-modal :visible="exitpreviewVisible" :footer="null" @cancel="exithandleCancel">
                <img alt="example" style="width: 100%" :src="exitpreviewImage" />
              </a-modal>
            </div>
          </a-form-item>
          <a-form-item label="是否分享给司机填写" >
            <a-switch v-decorator="['can_public_edit', {initialValue: false, valuePropName: 'checked'}]" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { tag_update } from '@/api/tag'
import { oss_token_list } from '@/api/oss_token'
const fields = ['id', 'create_time', 'corporation', 'entrance', 'exit', 'entrance_img', 'exit_img']
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      id: 0,
      destroyOnClose: true,
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      dataSource: [],
      corList: [],
      headers: {},
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      ImgUrl: '',
      fileList: [],
      exitfileList: [],
      exitpreviewVisible: false,
      exitpreviewImage: '',
      exitImgUrl: '',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      new_exit_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      this.loadEditInfo(this.record)
    })
  },
  created () {
    console.log('custom modal created', this.form)
    this.fileList = []

    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      // 出口
      this.new_exit_multipart_params.OSSAccessKeyId = data.accessid
      this.new_exit_multipart_params.policy = data.policy
      this.new_exit_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          // eslint-disable-next-line no-console
          console.log('Received values of form: ', values)
        }
        tag_update(values, this.id)
          .then((res) => {
            this.$notification.success({
              message: '修改成功',
              description: `修改成功`
            })
            this.handleGoBack()
            console.log(res, '修改成功')
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['id', 'name', 'color', 'description'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
