<template>
  <div>
    <!--    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      <a-button type="primary" icon="plus" @click="handleDriverAdd">创建司机填写表单</a-button>
    </div>-->

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="企业">
              <a-auto-complete
                v-model="queryParam.corporation"
                :data-source="dataSource"
                placeholder="请输入"
                @select="onSelect"
                @search="onSearch"
                @change="onChange"
              />
              <span>{{ tip }}</span>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="车牌号">
              <a-input v-model="queryParam.number" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="使用状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="2">正常</a-select-option>
                <a-select-option value="5">已取消</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="!advanced && 4 || 2" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <!--              <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
            </span>-->
            </span></a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <div
        slot="expandedRowRender"
        slot-scope="record"
        style="margin: 0">
        <a-row
          :gutter="24"
          :style="{ marginBottom: '12px' }">
          <a-col :span="8">
            创建时间：{{ record.create_time }}
          </a-col>
          <a-col :span="8">
            创建人：{{ record.approve_by }}
          </a-col>
        </a-row>
      </div>
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="color" slot-scope="color">
        <a-tag
          :color="color"
        >
          {{ color }}
        </a-tag>
      </span>
      <span slot="entrance_img" slot-scope="entrance_img">
        <a-avatar
          slot="entrance_img"
          :src="entrance_img"
          shape="square"
          @click="openPreview(entrance_img)"
        />
      </span>
      <span slot="exit_img" slot-scope="exit_img">
        <a-avatar
          slot="exit_img"
          :src="exit_img"
          shape="square"
          @click="openPreview(exit_img)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="share(record)">分享</a>
          <a-divider type="vertical" />
          <a @click="handleDelet(record)">取消</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="sharevisible" title="分享表单" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="codeurl" />
    </a-modal>
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { gateway_application_list, gateway_application_create, gateway_application_delete } from '@/api/gateway_application'
import { we_chat_code_create } from '@/api/we_chat'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/GatewayApplicationForm'
import { corporation_list } from '@/api/corporation'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      dataSource: [],
      corporation_id: null,
      tip: '',
      corList: [],
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      sharevisible: false,
      previewvisible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // code二维码图片
      codeurl: '',
      // 预览图片地址
      previewurl: '',
      wx_param: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '企业名称',
          dataIndex: 'corporation',
          ellipsis: true
        },
        {
          title: '进口货物',
          dataIndex: 'entrance',
          ellipsis: true
        },
        {
          title: '出口货物',
          dataIndex: 'exit',
          ellipsis: true
        },
        {
          title: '进口图片',
          dataIndex: 'entrance_img',
          scopedSlots: { customRender: 'entrance_img' }
        },
        {
          title: '出口图片',
          dataIndex: 'exit_img',
          scopedSlots: { customRender: 'exit_img' }
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => text === 5 && '已取消' || '正常'
        },
        {
          title: '是否授权司机填写',
          dataIndex: 'can_public_edit',
          customRender: (text) => text && '是' || '否'
        }/*,
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        } */
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return gateway_application_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {},
  methods: {
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        if (reslut.length === 0) {
          this.tip = '未找到您输入的企业'
        } else {
          this.tip = ''
        }
        console.log('我是企业列表', reslut)
        const list = reslut.map((item) => item.name)
        this.dataSource = !searchText ? [] : list
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id

      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleDriverAdd () {
      gateway_application_create({ can_public_edit: true, corporation_id: null }).then(({ data }) => {
        // 刷新表格
        this.$refs.table.refresh()
        console.log('删除成功------')
        console.log('我是提交成功表单之后的数据', data)
        const param = {
          scene: data.uid,
          page: 'pages/form/form'
        }
        we_chat_code_create(param).then(({ data }) => {
          console.log(data, '我是请求回来的数据')
          this.codeurl = 'data:image/png;base64,' + data
          this.sharevisible = true
        })
      }, (errors) => {
        console.log(errors)
      })
    },
    share (record) {
      console.log(record, '我是分享')
      const param = {
        scene: record.uid,
        page: 'pages/form/form'
      }
      we_chat_code_create(param).then(({ data }) => {
        console.log(data, '我是请求回来的数据')
        this.codeurl = 'data:image/png;base64,' + data
        this.sharevisible = true
      })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      gateway_application_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 处理车牌号数组问题
          values.numbers = values.numbers.split('+')
          // 新增
          gateway_application_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
            console.log('删除成功------')
          }, (errors) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
