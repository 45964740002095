import request from '@/utils/request'

const gateway_applicationApi = {
  gateway_application_list: '/gateway_application/',
  gateway_application_create: '/gateway_application/',
  gateway_application_update: '/gateway_application/',
  gateway_application_partial_update: '/gateway_application/',
  gateway_application_delete: '/gateway_application/'
}

/**
 * 列表
 */
export function gateway_application_list (parameter) {
  return request({
    url: gateway_applicationApi.gateway_application_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function gateway_application_create (parameter) {
  return request({
    url: gateway_applicationApi.gateway_application_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function gateway_application_update (parameter, gateway_application_id) {
  return request({
    url: gateway_applicationApi.gateway_application_update + gateway_application_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 保安修改
 * @param
 * {
 *  "change_to": 0
 * }
 */
export function gateway_application_partial_update (parameter, gateway_application_id) {
  return request({
    url: gateway_applicationApi.gateway_application_partial_update + gateway_application_id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function gateway_application_delete (gateway_application_id) {
  return request({
    url: gateway_applicationApi.gateway_application_delete + gateway_application_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
