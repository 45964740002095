<template>
  <div>
    <a-modal
      title="创建进出口申请单"
      :width="1080"
      :visible="visible"
      :confirmLoading="loading"
      :destroyOnClose="destroyOnClose"
      @ok="modalok"
      @cancel="modalcancel"
      :forceRender="true"
    >
      <a-spin :spinning="loading">
        <a-form :form="form" v-bind="formLayout">
          <a-row :gutter="[16,16]">
            <a-col :span="12">
              <a-form-item label="车牌号">
                <a-select
                  v-decorator="['numbers', {rules: [{required: false, message: '必填项，请填写信息'}]}]"
                  mode="tags"
                  style="width: 100%"
                  :token-separators="['+']"
                  @change="handleNumbersChange"
                >
                  <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
                    {{ (i + 9).toString(36) + i }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="进口货物信息">
                <a-input v-decorator="['entrance', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="出口货物信息">
                <a-input v-decorator="['exit', {rules: [{required: false, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
              </a-form-item>
              <a-form-item label="进口图片信息" v-show="false">
                <a-input v-decorator="['entrance_img', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
              </a-form-item>
              <a-form-item label="进口图片上传">
                <div class="clearfix">
                  <a-upload
                    v-decorator="[
                      'entrance_img_all',
                      {
                        valuePropName: 'entrance_img_all'
                      },
                    ]"
                    name="file"
                    accept="image/png, image/jpeg, image/jpg"
                    :action="actionUrl"
                    :data="new_multipart_params"
                    list-type="picture-card"
                    :file-list="fileList"
                    :before-upload="beforeUpload"
                    @preview="handlePreview"
                    @change="handleChange"
                  >
                    <div v-if="fileList.length < 1">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        Upload
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="example" style="width: 100%" :src="previewImage" />
                  </a-modal>
                </div>
              </a-form-item>
              <a-form-item label="出口图片信息" v-show="false">
                <a-input v-decorator="['exit_img', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
              </a-form-item>
              <a-form-item label="出口图片上传">
                <div class="clearfix">
                  <a-upload
                    v-decorator="[
                      'exit_img_all',
                      {
                        valuePropName: 'exit_img_all'
                      },
                    ]"
                    name="file"
                    accept="image/png, image/jpeg, image/jpg"
                    :action="actionUrl"
                    :data="new_exit_multipart_params"
                    list-type="picture-card"
                    :file-list="exitfileList"
                    :before-upload="exitbeforeUpload"
                    @preview="exithandlePreview"
                    @change="exithandleChange"
                  >
                    <div v-if="exitfileList.length < 1">
                      <a-icon type="plus" />
                      <div class="ant-upload-text">
                        Upload
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="exitpreviewVisible" :footer="null" @cancel="exithandleCancel">
                    <img alt="example" style="width: 100%" :src="exitpreviewImage" />
                  </a-modal>
                </div>
              </a-form-item>
              <a-form-item label="是否分享给司机填写" v-show="false">
                <a-switch v-decorator="['can_public_edit', {initialValue: false, valuePropName: 'checked'}]" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { oss_token_list } from '@/api/oss_token'

// 表单字段
const fields = ['id', 'create_time', 'corporation', 'entrance', 'exit', 'entrance_img', 'exit_img']
function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(console.log(reader.result)))
  reader.readAsDataURL(img)
}
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {}
    return {
      destroyOnClose: true,
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      dataSource: [],
      corList: [],
      headers: {},
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      ImgUrl: '',
      fileList: [],
      exitfileList: [],
      exitpreviewVisible: false,
      exitpreviewImage: '',
      exitImgUrl: '',
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      new_exit_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    this.fileList = []

      oss_token_list().then(({ data }) => {
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      // 出口
      this.new_exit_multipart_params.OSSAccessKeyId = data.accessid
      this.new_exit_multipart_params.policy = data.policy
      this.new_exit_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleNumbersChange (value) {
      console.log(`selected ${value}`)
    },
    modalcancel (e) {
      this.$emit('cancel')
    /*  this.previewImage = ''
      this.fileList = [] */
    },
    modalok () {
      this.$emit('ok')
    /*  this.previewImage = ''
      this.fileList = [] */
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {})
      }
      console.log('我要预览', file)
      this.previewImage = file.url || file.preview || file.thumbUrl
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ entrance_img: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ entrance_img: this.new_multipart_params.key })
      }
    },
    beforeUpload (file) {
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_multipart_params.key = file.uid + '.' + suffix
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      return isJpgOrPng && isLt2M
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    },
    // 出口图片
    async exithandlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, () => {})
      }
      console.log('我要预览', file)
      this.exitpreviewImage = file.url || file.preview || file.thumbUrl
      this.exitpreviewVisible = true
    },
    exithandleChange ({ fileList, file }) {
      this.exitfileList = fileList
      console.log('我是出口图片列表', fileList)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ exit_img: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ exit_img: this.new_exit_multipart_params.key })
      }
    },
    exitbeforeUpload (file) {
      // this.new_exit_multipart_params.key = Math.floor(Math.random() * 65535).toString(32) + file.name
      const name = file.name
      const suffix = name.substring(name.lastIndexOf('.') + 1)
      this.new_exit_multipart_params.key = file.uid + '.' + suffix
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
       if (!isJpgOrPng) {
         this.$message.error('You can only upload JPG file!')
       }
       const isLt2M = file.size / 1024 / 1024 < 2
       return isJpgOrPng && isLt2M
    },
    exithandleCancel () {
      this.exitpreviewVisible = false
    }
  }
}
</script>
<style>
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
