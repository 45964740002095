import request from '@/utils/request'

const wxApi = {
  we_chat_code_create: '/we_chat/code/'
}

/**
 * 生成小程序码,字段参见微信文档
 */
export function we_chat_code_create (parameter) {
  return request({
    url: wxApi.we_chat_code_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
